/* Fullscreen mode */
.Dashboard.DashboardFullscreen {
  min-height: 100vh;
  height: auto;
}

/* Night mode */
.Dashboard.DashboardNight {
  background-color: var(--mb-color-bg-black);
}

.Dashboard.DashboardNight .Card {
  color: var(--color-text-white);
}

.Dashboard.DashboardFullscreen .fullscreenNormalText {
  color: var(--mb-color-text-dark);
  transition: color 1s linear;
}

.Dashboard.DashboardNight.DashboardFullscreen .fullscreenNightText {
  color: color-mod(var(--color-text-white) alpha(-14%));
  transition: color 1s linear;
}

.Dashboard.DashboardNight .DashCard .Card svg text {
  fill: color-mod(var(--color-text-white) alpha(-14%)) !important;
  stroke: none !important;
}

.Dashboard.DashboardNight
  .enableDotsOnHover
  :global(.dc-tooltip circle.dot:hover),
.Dashboard.DashboardNight .enableDots :global(.dc-tooltip circle.dot) {
  fill: currentColor;
}

/* Night mode transition */
.Dashboard.DashboardFullscreen,
.Dashboard.DashboardFullscreen .DashCard .Card {
  transition: background-color 1s linear, border 1s linear;
}

.DashEditing {
  margin-top: 1.5em;
}

.DashEditing .DashCard .Card {
  transition: border 0.3s, background-color 0.3s;
}

.DashEditing .CardTitle:first-of-type {
  margin-top: 0.5rem;
}

.DashEditing .CardTitle {
  pointer-events: none;
}

.DashEditing .PinMap {
  /* allow map to pan. need to stopPropagation in PinMap to prevent weird dragging interaction */
  pointer-events: all;
}

.PinMapUpdateButtonDisabled {
  pointer-events: none;
  color: var(--mb-color-text-light);
}

/* .react-draggable*, .react-resizable* are 3rd party library classes */
.DashEditing .DashCard:global(.react-draggable-dragging) .Card {
  box-shadow: 3px 3px 8px var(--mb-color-shadow);
}

.BrandColorResizeHandle :global(.react-resizable-handle::after) {
  border-color: var(--mb-color-brand) !important;
}

.DashEditing .DashCard:global(.react-draggable-dragging),
.DashEditing .DashCard:global(.react-resizable-resizing) {
  z-index: 3;
}

.DashEditing .DashCard:global(.react-draggable-dragging) .Card,
.DashEditing .DashCard:global(.react-resizable-resizing) .Card {
  background-color: var(--color-bg-medium) !important;
  border: 1px solid var(--mb-color-brand);
}

.DashEditing .DashCard .VisualizationSlowSpinner {
  position: absolute;
  right: -2px;
  top: -2px;
}

.DashEditing .DashCard:hover .VisualizationSlowSpinner {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.DashEditing .DashCard {
  cursor: move;
}

.DashEditing .DashCard :global(.react-resizable-handle) {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 0;
  right: 0;
  z-index: 1; /* ensure the handle is above the card contents */

  /* overwrites the style coming from react-grid-layout */
  cursor: nwse-resize !important;
  background: none; /* hide default RGL's resize handle */
}

.DashEditing .DashCard :global(.react-resizable-handle::after) {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  bottom: 6px;
  right: 6px;
  border-bottom: 2px solid var(--mb-color-border);
  border-right: 2px solid var(--mb-color-border);
  border-bottom-right-radius: 2px;
  transition: opacity 0.2s;
  opacity: 0.01;
}

.DashEditing .DashCard :global(.react-resizable-handle:hover::after) {
  border-color: var(--mb-color-border);
}

.DashEditing .DashCard:hover :global(.react-resizable-handle::after) {
  opacity: 1;
}

.DashEditing
  .DashCard:global(.react-draggable-dragging .react-resizable-handle::after),
.DashEditing
  .DashCard:global(.react-resizable-resizing .react-resizable-handle::after) {
  opacity: 0.01;
}

/* .react-grid-placeholder is a global class from `react-grid-layout` */
.DashEditing :global(.react-grid-placeholder) {
  z-index: 0;
  background-color: var(--mb-color-bg-light) !important;
  transition: all 0.15s linear;
}

/* Used in frontend/src/metabase/dashboard/components/DashCard/DashCardActionsPanel/DashCardActionsPanel.styled.tsx */
/* stylelint-disable-next-line block-no-empty */
.DashDragging {
}

.Modal.AddSeriesModal {
  height: 80%;
  max-height: 600px;
  width: 80%;
  max-width: 1024px;
}

/* what for to print the dashboards */
@media print {
  header,
  nav {
    display: none;
  }

  /* improve label contrast */
  :global(.dc-chart .axis .tick text),
  :global(.dc-chart .x-axis-label),
  :global(.dc-chart .y-axis-label) {
    fill: var(--mb-color-text-dark);
  }
}

@page {
  margin: 1cm;
}

/* when in night mode goal lines should be more visible */
.DashboardNight :global(.goal .line),
.DashboardNight :global(.trend .line) {
  stroke: white;
}

/* when in night mode code snippets should have a more readable background-color */
.DashboardNight pre code {
  background-color: color-mod(var(--mb-color-bg-white) alpha(-86%));
}

.DashboardNight :global(.text-card-markdown code) {
  background-color: color-mod(var(--mb-color-bg-white) alpha(-86%));
}

.Dashboard :global(text.value-label),
.Dashboard :global(text.value-label-white),
.Dashboard .LineAreaBarChart :global(.dc-chart .axis text) {
  font-size: 12px;
}

.ScalarValue {
  font-weight: 700;
  white-space: pre;
}

/*
 * Override d3 styles so the chart axis labels are selectable. See issue #17613
 */
:global(.dc-chart g.axis g.tick text) {
  pointer-events: all;
  user-select: auto;
}
